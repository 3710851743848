import { useCallback, useEffect, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useCreateCoinMutation, useGetAllCoinsQuery } from "../../store/coinsApi";

const UseSubmitCoinHooks = () => {

  const { data: createCoinPageData } = useGetAllCoinsQuery();

  const transformedOptions = useMemo(() => {
    return createCoinPageData?.networks?.map((option) => ({
      name: option.title,
      value: option.id,
    }));
  }, [createCoinPageData?.networks]);


  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      fare_launch: "yes",
      roadmaps: [{ step: "" }, { step: "" }],
      airdrops: {
        airdropLink: "",
        airdrop_start_time: "",
        airdrop_start_day: "",
        airdrop_start_month: "",
        airdrop_start_year: "",
        airdrop_end_time: "",
        airdrop_end_day: "",
        airdrop_end_month: "",
        airdrop_end_year: "",
      },
      platform: transformedOptions && transformedOptions[0]?.value
    },
  });

  const {
    fields: faqFields,
    append,
    removeFaq,
  } = useFieldArray({
    control,
    name: "faq",
  });

  const {
    fields: kycCertificateFields,
    append: appendNewKYCCertificate,
    remove: removeKYCCertificate,
  } = useFieldArray({
    control,
    name: "kycCertificates",
  });

  const {
    fields: roadmapFields,
    append: appendNewRoadmap,
    remove: removeRoadmap,
  } = useFieldArray({
    control,
    name: "roadmaps",
  });

  const {
    fields: auditFields,
    append: appendNewAudit,
    remove: removeAudit,
  } = useFieldArray({
    control,
    name: "audits",
  });

  const addNewFaq = useCallback(() => {
    append({ question: "", answer: "" });
  }, [append]);

  const addNewKYCCertificate = useCallback(() => {
    appendNewKYCCertificate({ link: "", comment: "" });
  }, [appendNewKYCCertificate]);

  const addNewRoadmap = useCallback(() => {
    appendNewRoadmap({ step: "" });
  }, [appendNewRoadmap]);

  const addNewAudit = useCallback(() => {
    appendNewAudit({ link: "", comment: "" });
  }, [appendNewAudit]);

  const [submitCoinReq, { isSuccess, isLoading }] = useCreateCoinMutation();

  const onSubmit = (data) => {
    const payload = {
      name: data?.name,
      symbol: data?.symbol,
      platform: data?.platform?.value,
      category: data?.category,
      about: {
        img: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fdesignpowers.com%2Fblog%2Furl-best-practices&psig=AOvVaw0yaV9FRSMfQKqcvuVZJErX&ust=1726909417031000&source=images&cd=vfe&opi=89978449&ved=0CBQQjRxqFwoTCJjy74SV0YgDFQAAAAAdAAAAABAE",
        contract_address: data?.contract_address,
        description: data?.description,
        youtube_link: data?.youtube_link,
        coingecko_link: data?.coingecko_link,
        pancakeswap_link: data?.pancakeswap_link,
        poocoin_link: data?.poocoin_link,
        presale_link: data?.presale_link,
        fare_launch: data?.fare_launch,
        softcap: data?.softcap,
        softcap_platform: data?.softcap_platform?.value,
        softcap_custom: data?.softcap_custom,
        presale_start: {
          time: +data?.start_time?.value,
          day: +data?.start_day?.value,
          month: data?.start_month?.value,
          year: +data?.start_year?.value,
        },
        presale_end: {
          time: +data?.end_time?.value,
          day: +data?.end_day?.value,
          month: data?.end_month?.value,
          year: +data?.end_year?.value,
        },
        launch_date_is_known: data?.launch_date_is_known ? true : false,
        launch_date:
          watch("launch_date_is_known") === "yes"
            ? {
                launch_time: +data?.launch_time?.value,
                launch_day: +data?.launch_day?.value,
                launch_month: data?.launch_month?.value,
                launch_year: +data?.launch_year?.value,
              }
            : null,
        custom_chart_link: data?.custom_chart_link,
        custom_swap_link: data?.custom_swap_link,
      },
      contact_info: {
        website_link: data?.website_link,
        email: data?.email,
        telegram: data?.telegram,
        twitter: data?.twitter && data?.twitter,
        discord: data?.discord && data?.discord,
        reddit: data?.raddit && data?.raddit,
        github: data?.github && data?.github,
        whitepaper: data?.whitepaper && data?.whitepaper,
      },
      faq: data?.faq,
      kycCertificates: data?.kycCertificates,
      roadmaps: data?.roadmaps,
      audits: data?.audits,
      airdrops: data?.airdrops,
    };    

    submitCoinReq(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      reset({});
    }
  }, [isSuccess]);

 

  return {
    kycCertificateFields,
    createCoinPageData,
    roadmapFields,
    auditFields,
    removeFaq,
    faqFields,
    isLoading,
    control,
    errors,
    watch,
    reset,
    onSubmit,
    addNewFaq,
    addNewAudit,
    removeAudit,
    handleSubmit,
    addNewRoadmap,
    removeRoadmap,
    removeKYCCertificate,
    addNewKYCCertificate,
  };
};

export default UseSubmitCoinHooks;
