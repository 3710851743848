import { useEffect, useRef, useState } from "react";
import style from "./Select.module.scss";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import Text from "../Text/Text";
import Checkbox from "../Checkbox/Checkbox";
import Input from "../Input/Input";
import Button from "../Button/Button";
import { countHidden } from "../../helpers/countHidden";
import TokenInfo from "../TokenInfo/TokenInfo";
import bscIcon from "../../assets/icons/chains/bsc.svg";
import { useCopyToClipboard } from "usehooks-ts";

const Select = ({
  label,
  className,
  big,
  border,
  icon,
  options,
  isCheckboxList,
  isProjectList,
  isRange,
  onChange,
  value,
  defaultValue,
}) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const outsideAlerterRef = useOutsideAlerter(() => setIsOpen(false));
  const [selectedCount, setSelectedCount] = useState(0);
  const [fromTo, setFromTo] = useState(false);
  const [dropdownRight, setDropdownRight] = useState(false);
  const [copiedText, copy] = useCopyToClipboard();

  const selectItem = (item) => {
    setSelectedOption(item);
    setIsOpen(false);
    onChange(item);
  };

  // useEffect(() => {
  //   options?.[0] && setSelectedOption(options?.[0]);
  // }, [options]);

  useEffect(() => {
    if (value) {
      const selected = options.find((option) => option.value === value);
      setSelectedOption(selected);
    }
  }, [value, options]);

  useEffect(() => {
    if (countHidden(dropdownRef.current).right > 0) {
      setDropdownRight(true);
    } else if (countHidden(dropdownRef.current).left > 0) {
      setDropdownRight(false);
    }
  }, [isOpen]);

  // for checkbox select
  const checkedListHandle = () => {
    if (isCheckboxList) {
      const inputList = Array.from(
        dropdownRef.current.querySelectorAll(`.${style.checkbox} input`)
      );
      setSelectedCount(inputList.filter((item) => item.checked).length);
    }
  };

  // for range select
  const handleInputFrom = ({ target }) => {
    setFromTo([target.value, fromTo[1]]);
  };
  const handleInputTo = ({ target }) => {
    setFromTo([fromTo[0], target.value]);
  };
  useEffect(() => {
    if (isRange) {
      if (!fromTo[0] && !fromTo[1]) {
        setFromTo(false);
      }
    }
  }, [fromTo, isRange]);

  return (
    <div
      ref={outsideAlerterRef}
      className={`${style.select} 
      ${big && style.big}
      ${dropdownRight && style.dropdownRight}
      ${isProjectList && style.isProjectList}
      ${border && style.border} ${className} ${isOpen && style.open}`}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={style.selectTop}
        type="button"
      >
        {icon && <div className={style.selectTop__icon}>{icon}</div>}
        {label && (
          <Text grey className={style.selectTop__label}>
            {label}
          </Text>
        )}
        <div
          className={`${style.selectTop__selected} ${
            selectedOption?.value === "" && style.placeholder
          }`}
        >
          {isRange &&
            fromTo &&
            `${fromTo[0] ? `$${fromTo[0]}` : "+"}${
              fromTo[1] ? `${fromTo[0] ? "-" : ""}$${fromTo[1]}` : "+"
            }`}
          {!fromTo && value?.name}
        </div>
        {isCheckboxList && selectedCount > 0 && (
          <div className={style.selectTop__count}>
            <Text black={!isOpen}>{selectedCount}</Text>
          </div>
        )}
        <div className={style.selectTop__arrow}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 6L8 10L12 6"
              stroke="white"
              stroke-opacity="0.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </button>
      {!isRange && !isProjectList && (
        <div ref={dropdownRef} className={style.selectList}>
          {options?.map(
            (item, index) =>
              item.value !== "" &&
              (isCheckboxList ? (
                <Checkbox
                  onChange={checkedListHandle}
                  className={style.checkbox}
                  title={item.name}
                  name={item.value}
                />
              ) : (
                <button
                  type="button"
                  key={index}
                  data-value={item.value}
                  className={style.selectList__item}
                  onClick={() => selectItem(item)}
                >
                  {item.name}
                </button>
              ))
          )}
        </div>
      )}
      {isProjectList && (
        <div ref={dropdownRef} className={style.selectList}>
          <div className={style.selectList__scroll}>
            {options?.map(
              (item, index) =>
                item.value !== "" && (
                  <button
                    type="button"
                    key={index}
                    data-value={item.value}
                    className={style.selectList__project}
                    onClick={() => selectItem(item)}
                  >
                    <TokenInfo
                      icon={item.icon}
                      name={item.name}
                      label={item.category}
                    />
                    <div className={style.selectList__projectAddress}>
                      <Text grey className={style.chain}>
                        {item.chain}
                        <img src={item.chain === "BSC" && bscIcon} alt="" />
                      </Text>
                      <div className={style.divider}></div>
                      <div className={style.address}>
                        <Text grey>0xDbC3...e382</Text>
                        <button
                          type="button"
                          onClick={() => {
                            copy("0xDbC3...e382");
                          }}
                        >
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.75 1.5H7.3C8.42011 1.5 8.98016 1.5 9.40798 1.71799C9.78431 1.90973 10.0903 2.21569 10.282 2.59202C10.5 3.01984 10.5 3.5799 10.5 4.7V8.25M3.1 10.5H7.15C7.71005 10.5 7.99008 10.5 8.20399 10.391C8.39215 10.2951 8.54513 10.1422 8.64101 9.95399C8.75 9.74008 8.75 9.46005 8.75 8.9V4.85C8.75 4.28995 8.75 4.00992 8.64101 3.79601C8.54513 3.60785 8.39215 3.45487 8.20399 3.35899C7.99008 3.25 7.71005 3.25 7.15 3.25H3.1C2.53995 3.25 2.25992 3.25 2.04601 3.35899C1.85785 3.45487 1.70487 3.60785 1.60899 3.79601C1.5 4.00992 1.5 4.28995 1.5 4.85V8.9C1.5 9.46005 1.5 9.74008 1.60899 9.95399C1.70487 10.1422 1.85785 10.2951 2.04601 10.391C2.25992 10.5 2.53995 10.5 3.1 10.5Z"
                              stroke="white"
                              stroke-opacity="0.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </button>
                )
            )}
          </div>
        </div>
      )}
      {isRange && (
        <div ref={dropdownRef} className={style.selectRange}>
          <div className={style.selectRange__body}>
            <Input
              className={style.selectRange__bodyItem}
              placeholder={"$Min"}
              border
              dark
              onChange={handleInputFrom}
            />
            <Input
              className={style.selectRange__bodyItem}
              placeholder={"$Max"}
              border
              dark
              onChange={handleInputTo}
            />
          </div>
          <div className={style.selectRange__btns}>
            <Button type="button" noBorder dark>
              Reset
            </Button>

            <Button filledDark type="button">
              Apply
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Select;
