import { useMediaQuery } from "usehooks-ts";
import style from "./index.module.scss";
import Progress from "./modules/Progress/Progress";
import ProgressMobile from "./modules/ProgressMobile/ProgressMobile";
import GeneralSection from "./sections/General/General";
import SubmitCoinTop from "./sections/Top/Top";
import CategorySection from "./sections/Category/Category";
import AboutSection from "./sections/About/About";
import ContactSection from "./sections/Contact/Contact";
import FaqSection from "./sections/Faq/Faq";
import KycSection from "./sections/Kyc/Kyc";
import RoadmapSection from "./sections/Roadmap/Roadmap";
import AuditSection from "./sections/Audit/Audit";
import AirdropSection from "./sections/Airdrop/Airdrop";
import { useEffect, useState } from "react";
import { useGetAllCoinsQuery } from "../../store/coinsApi";
import UseSubmitCoinHooks from "./submitCoin.hooks";
import Text from "../../components/Text/Text";
import Button from "../../components/Button/Button";
import ClipLoader from "react-spinners/ClipLoader";

const SubmitCoinPage = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const [progress, setProgress] = useState(0);
  const [generalProgress, setGeneralProgress] = useState(0);
  const [categoryProgress, setCategoryProgress] = useState(0);
  const [aboutProgress, setAboutProgress] = useState(0);
  const [contactsProgress, setContactsProgress] = useState(0);

  useEffect(() => {
    setProgress(
      generalProgress + categoryProgress + aboutProgress + contactsProgress
    );
  }, [generalProgress, categoryProgress, aboutProgress, contactsProgress]);

  const handleSuccessGeneral = (status) => {
    if (status) {
      setGeneralProgress(25);
    } else {
      setGeneralProgress(0);
    }
  };
  const handleSuccessCategory = (status) => {
    if (status) {
      setCategoryProgress(25);
    } else {
      setCategoryProgress(0);
    }
  };
  const handleSuccessAbout = (status) => {
    if (status) {
      setAboutProgress(25);
    } else {
      setAboutProgress(0);
    }
  };
  const handleSuccessContacts = (status) => {
    if (status) {
      setContactsProgress(25);
    } else {
      setContactsProgress(0);
    }
  };

  const {
    handleSubmit,
    control,
    onSubmit,
    watch,
    reset,
    faqFields,
    addNewFaq,
    removeFaq,
    kycCertificateFields,
    addNewKYCCertificate,
    removeKYCCertificate,
    roadmapFields,
    addNewRoadmap,
    removeRoadmap,
    auditFields,
    addNewAudit,
    removeAudit,
    isLoading,
    createCoinPageData,
    transformedOptions,
    errors
  } = UseSubmitCoinHooks();

  return (
    <div className={style.coin}>
      <SubmitCoinTop />
      {isMobile && <ProgressMobile />}
      <div className="__container">
        <div className={style.coinBody}>
          <div className={style.coinBody__content}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <GeneralSection
                handleSuccess={handleSuccessGeneral}
                networkList={createCoinPageData?.networks}
                control={control}
                errors={errors}
              />
              <CategorySection
                handleSuccess={handleSuccessCategory}
                categories={createCoinPageData?.categories}
                control={control}
              />
              <AboutSection
                handleSuccess={handleSuccessAbout}
                control={control}
                watch={watch}
              />
              <ContactSection
                handleSuccess={handleSuccessContacts}
                control={control}
              />
              <FaqSection
                control={control}
                fields={faqFields}
                addNewFaq={addNewFaq}
                remove={removeFaq}
                watch={watch}
              />
              <KycSection
                control={control}
                kycCertificateFields={kycCertificateFields}
                addNewKYCCertificate={addNewKYCCertificate}
                removeKYCCertificate={removeKYCCertificate}
              />
              <RoadmapSection
                control={control}
                roadmapFields={roadmapFields}
                addNewRoadmap={addNewRoadmap}
                removeRoadmap={removeRoadmap}
              />
              <AuditSection
                control={control}
                auditFields={auditFields}
                addNewAudit={addNewAudit}
                removeAudit={removeAudit}
              />
              <AirdropSection control={control} reset={reset} />
              <div className={style.bottom}>
                <Text grey className={style.bottom__text}>
                  By clicking “Submit” you agree <br /> with our{" "}
                  <a href="">Terms and Conditions</a>
                </Text>
                <Button filled className={style.bottom__btn} type={'submit'}>
                  {isLoading ? (
                    <ClipLoader
                      color={"black"}
                      size={15}
                    />
                  ) : (
                    "Submit Coin"
                  )}
                </Button>
              </div>
            </form>
          </div>

          {!isMobile && (
            <div className={style.coinBody__side}>
              <Progress
                generalProgress={generalProgress}
                categoryProgress={categoryProgress}
                aboutProgress={aboutProgress}
                contactsProgress={contactsProgress}
                progress={progress}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubmitCoinPage;
