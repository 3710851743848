import Button from '../Button/Button';
import Text from '../Text/Text';
import style from './Header.module.scss';
import greenCrown from './assets/linkIcons/green-crown.svg';
import logo from '../../assets/logo.svg';
import Input from '../Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'usehooks-ts';
import Marquee from 'react-fast-marquee';
import Menu from '../Menu/Menu';
import { useEffect, useRef, useState } from 'react';
import Search from '../Search/Search';
import RegistrationModal from '../modals/Registration/Registration';
import { getAllCoins, resetCoinsList } from '../../store/searchCoinsSlice';

// import btn icons
import crown from '../../assets/btn_icons/crown.svg';
import folder from '../../assets/btn_icons/folder.svg';
import presale from '../../assets/btn_icons/presale.svg';
import cloud from '../../assets/btn_icons/cloud.svg';
import RecoveryModal from '../modals/Recovery/Recovery';
import { useLogoutMutation } from '../../store/authApi';
import { Link, Navigate, useNavigate } from 'react-router-dom';

const statsList = [
  {
    text: 'Projects Listed:',
    value: '59,805',
  },
  {
    text: 'Total Votes:',
    value: '237,426,119',
  },
  {
    text: 'Watchlists:',
    value: '2,279,281',
  },
  {
    text: 'Total KYCs:',
    value: '1,468',
  },
  {
    text: 'Total Audits:',
    value: '2,356',
  },
  {
    text: 'Supported Chains:',
    value: '10',
  },
];

const Header = ({ headerData, menu, popupMenu }) => {
  const isMobile = useMediaQuery('(max-width:991px)');
  const isMobile768 = useMediaQuery('(max-width:767px)');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const headerRef = useRef(null);
  let [prevScrollpos, setPrevScrollpos] = useState(0);
  const [currentSection, setCurrentSection] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [allow, setAllow] = useState(true);

  const openMenu = () => {
    setIsMenuOpen(true);
    closeSearch();
    document.body.classList.add('_lock');
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
    document.body.classList.remove('_lock');
  };
  const openSearch = () => {
    closeMenu();
    setIsSearchOpen(true);
    document.body.classList.add('_lock');
  };
  const closeSearch = () => {
    setIsSearchOpen(false);
    document.body.classList.remove('_lock');
  };

  function handleSticky() {
    const sticky = headerRef.current.offsetTop;
    if (window.pageYOffset > sticky) {
      // headerRef.current.classList.add('_scrolled');
      headerRef.current.classList.add(style.scrolled);
    } else {
      // headerRef.current.classList.remove('_scrolled');
      headerRef.current.classList.remove(style.scrolled);
    }
  }

  useEffect(() => {
    window.onscroll = function () {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        headerRef.current.classList.add(style.visible);
      } else {
        headerRef.current.classList.remove(style.visible);
      }
      // prevScrollpos = currentScrollPos;
      setPrevScrollpos(currentScrollPos);
    };
    window.addEventListener('scroll', handleSticky);
  }, [prevScrollpos]);

  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValue.length > 2) {
        dispatch(getAllCoins(searchValue));
      } else {
        dispatch(resetCoinsList());
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue, dispatch]);

  const coinsList = useSelector((state) => state?.coins?.coins?.list?.coins);
  const searchCoinsStatus = useSelector((state) => state?.coins?.coins?.status);

  return (
    <>
      <header
        id='header'
        ref={headerRef}
        className={`${style.header} ${isSearchOpen && style.searchOpen}`}
      >
        <div className={`${style.con} __container`}>
          {!isMobile && (
            <div className={style.headerStats}>
              {statsList.map((item, index) => (
                <StatsItem key={index} text={item.text} value={item.value} />
              ))}
            </div>
          )}
          {isMobile && (
            <Marquee speed={30} className={style.headerMarquee}>
              <div className={style.headerStats}>
                {statsList.map((item, index) => (
                  <StatsItem key={index} text={item.text} value={item.value} />
                ))}
              </div>
            </Marquee>
          )}
          <div className={`${style.headerContent}`}>
            <div className={style.headerBody}>
              {isMobile768 && !isSearchOpen && (
                <div className={style.headerBody__logo}>
                  <img src={logo} alt='' />
                </div>
              )}
              {!isMobile768 && (
                <div className={style.headerBody__logo}>
                  <img src={logo} alt='' />
                </div>
              )}
              {isMobile && (
                <>
                  {isSearchOpen && (
                    <Input
                      className={style.headerBody__search}
                      onClick={openSearch}
                      placeholder={'Search coin'}
                      withClear
                      icon={
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M14 14L11.1 11.1M12.6667 7.33333C12.6667 10.2789 10.2789 12.6667 7.33333 12.6667C4.38781 12.6667 2 10.2789 2 7.33333C2 4.38781 4.38781 2 7.33333 2C10.2789 2 12.6667 4.38781 12.6667 7.33333Z'
                            stroke='white'
                            stroke-opacity='0.5'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                          />
                        </svg>
                      }
                    />
                  )}
                  {!isSearchOpen && (
                    <Button
                      onClick={openSearch}
                      onlyIcon
                      className={style.headerBody__search}
                    >
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M14 14L11.1 11.1M12.6667 7.33333C12.6667 10.2789 10.2789 12.6667 7.33333 12.6667C4.38781 12.6667 2 10.2789 2 7.33333C2 4.38781 4.38781 2 7.33333 2C10.2789 2 12.6667 4.38781 12.6667 7.33333Z'
                          stroke='white'
                          stroke-opacity='0.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    </Button>
                  )}
                  <Button
                    filled={isMenuOpen}
                    onClick={isMenuOpen ? closeMenu : openMenu}
                  >
                    Menu
                    {isMenuOpen ? (
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M12 4L4 12M4 4L12 12'
                          stroke='#010106'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    ) : (
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6.33325H12'
                          stroke='white'
                          stroke-opacity='0.5'
                          stroke-linecap='round'
                        />
                        <path
                          d='M4 9.6665H12'
                          stroke='white'
                          stroke-opacity='0.5'
                          stroke-linecap='round'
                        />
                      </svg>
                    )}
                  </Button>
                </>
              )}
              {!isMobile && (
                <>
                  <Input
                    onChange={(e) => {
                      setSearchValue(e?.target?.value);
                    }}
                    value={searchValue}
                    className={style.headerBody__search}
                    placeholder={'Search coin'}
                    onClick={openSearch}
                    withClear
                    icon={
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M14 14L11.1 11.1M12.6667 7.33333C12.6667 10.2789 10.2789 12.6667 7.33333 12.6667C4.38781 12.6667 2 10.2789 2 7.33333C2 4.38781 4.38781 2 7.33333 2C10.2789 2 12.6667 4.38781 12.6667 7.33333Z'
                          stroke='white'
                          stroke-opacity='0.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    }
                  />
                  <div className={style.headerBody__btns}>
                    <Button onClick={openMenu}>
                      Menu
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6.33325H12'
                          stroke='white'
                          stroke-opacity='0.5'
                          stroke-linecap='round'
                        />
                        <path
                          d='M4 9.6665H12'
                          stroke='white'
                          stroke-opacity='0.5'
                          stroke-linecap='round'
                        />
                      </svg>
                    </Button>
                    <Button filled onClick={() => navigate('/submit_coin')}>
                      Submit Coin
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8.00016 3.33325V12.6666M3.3335 7.99992H12.6668'
                          stroke='#010106'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    </Button>
                    {!localStorage.getItem('coin-gems-access-token') && (
                      <RegistrationModal btnText='Login' />
                    )}
                    <Button>
                      Create Account
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4.66675 11.3333L11.3334 4.66663M11.3334 4.66663H4.66675M11.3334 4.66663V11.3333'
                          stroke='white'
                          stroke-opacity='0.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    </Button>
                  </div>
                </>
              )}
            </div>
            {!isMobile && (
              <div className={style.headerLinks}>
                {menu?.map((item, index) => {
                  return (
                    <Button
                      key={index}
                      icon={crown}
                      onClick={() => closeMenu()}
                    >
                      <Link to={item?.url}>{item?.title}</Link>
                    </Button>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </header>
      <div>logout</div>
      <Menu
        isOpen={isMenuOpen}
        closeMenu={closeMenu}
        headerData={headerData}
        popupMenu={popupMenu}
      />
      <Search
        isOpen={isSearchOpen}
        closeSearch={closeSearch}
        coinsList={coinsList}
      />
    </>
  );
};

const StatsItem = ({ text, value }) => {
  return (
    <div className={style.statsItem}>
      <Text grey>{text}</Text>
      <Text>{value}</Text>
    </div>
  );
};

export default Header;
