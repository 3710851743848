import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './API';

export const coinsApi = createApi({
  reducerPath: 'coinsApi',
  tagTypes: ['Coins'],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    getAllCoins: builder.query({
      query: () => ({
        url: `/coins/create`,
        method: 'GET',
      }),
      invalidatesTags: ['Coins'],
    }),
    getCoinBySlug: builder.query({
      query: (slug) => ({
        url: `/coins/${slug}`,
        method: 'GET',
      }),
      invalidatesTags: ['Coins'],
    }),
    getCoinsTableByType: builder.query({
      query: ({ type, page }) => ({
        url: `/coins-table/${type}`,
        method: 'GET',
        params: page ? { page } : undefined,
      }),
      invalidatesTags: ['Coins'],
    }),
    voteCoins: builder.mutation({
      query: (data) => ({
        url: `/coins/${data?.coin}/vote`,
        body: { coin: data?.vote },
        method: 'POST',
      }),
      invalidatesTags: ['Coins'],
    }),
    boostCoins: builder.mutation({
      query: (data) => ({
        url: `/coins/${data?.coin}/boost`,
        data: { quantity: data?.quantity },
        method: 'POST',
      }),
      invalidatesTags: ['Coins'],
    }),
    createCoin: builder.mutation({
      query: (data) => ({
        url: `/coins`,
        data: data,
        method: 'POST',
      }),
      invalidatesTags: ['Coins'],
    }),
  }),
});

export const {
  useGetAllCoinsQuery,
  useVoteCoinsMutation,
  useGetCoinBySlugQuery,
  useBoostCoinsMutation,
  useCreateCoinMutation,
  useGetCoinsTableByTypeQuery,
} = coinsApi;
