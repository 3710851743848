import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './API';
import Cookies from 'js-cookie';

export const authApi = createApi({
  reducerPath: 'authApi',
  tagTypes: ['Auth'],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_AUTH_URL,
    credentials: 'include',
    prepareHeaders: (headers, { getState, endpoint }) => {
      const csrfToken = Cookies.get('XSRF-TOKEN');      
      if (csrfToken) {
        headers.set('X-XSRF-TOKEN', csrfToken);        
      }      
      return headers;
    },
  }),
  endpoints: (builder) => ({
    verifyEmail: builder.query({
      query: ({ id, hash }) => ({
        url: `/verify-email/${id}/${hash}`,
        method: 'GET',
      }),
      invalidatesTags: ['Auth'],
    }),
    registerUser: builder.mutation({
      query: (data) => ({
        url: `/register`,
        data: data,
        method: 'POST',
      }),
      invalidatesTags: ['Auth'],
    }),
    forgetPassword: builder.mutation({
      //email
      query: (data) => ({
        url: `/forgot-password`,
        data: data,
        method: 'POST',
      }),
      invalidatesTags: ['Auth'],
    }),
    resetPassword: builder.mutation({
      // token, email, password, password_confirmation
      query: (data) => ({
        url: `/reset-password`,
        data: data,
        method: 'POST',
      }),
      invalidatesTags: ['Auth'],
    }),
    login: builder.mutation({
      query: (data) => ({
        url: `/login`,
        data: data,
        method: 'POST',
      }),
      invalidatesTags: ['Auth'],
    }),
    logout: builder.mutation({
      query: () => ({
        url: `/logout`,
        method: 'POST',
      }),
      invalidatesTags: ['Auth'],
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useVerifyEmailQuery,
  useRegisterUserMutation,
  useResetPasswordMutation,
  useForgetPasswordMutation,
} = authApi;
