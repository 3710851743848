import Table from '../../../../components/Table/Table';
import TokenInfo from '../../../../components/TokenInfo/TokenInfo';
import style from './Boosted.module.scss';
import img from '../../../../components/Search/modules/Slider/assets/logo.jpg';
import fireIcon from '../../../../assets/btn_icons/boost.svg';
import Button from '../../../../components/Button/Button';
import { useMediaQuery } from 'usehooks-ts';
import { useContext, useState } from 'react';
import Empty from '../../../../components/Empty/Empty';
import firedIcon from '../../../../assets/icons/fired.svg';
import { MyDataContext } from '../../../Advertise';
import { useGetPromotionsQuery } from '../../../../store/promotionsApi';
import { useGetBoostersProjectsQuery } from '../../../../store/boosterApi';
import { useNavigate } from 'react-router-dom';

const BoostedSection = () => {
  const isTablet = useMediaQuery('(max-width:991px)');
  const [isEmpty, setIsEmpty] = useState(false);
  const navigate = useNavigate();

  const category = useContext(MyDataContext);
  const { data: boostedProjects } = useGetBoostersProjectsQuery();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  return (
    <div className={style.boosted}>
      {isEmpty ? (
        <div className={style.empty}>
          <Empty
            icon={firedIcon}
            title={
              <>
                So far, you have <br /> not purchased Boost
              </>
            }
            text={
              <>
                The more boosts, the higher <br /> you are among other projects
              </>
            }
            button={<Button>Buy Boost</Button>}
          />
        </div>
      ) : (
        <Table.Container>
          <Table.Head>
            <Table.Row>
              <Table.Col>Project name</Table.Col>
              {/* {!isTablet && <Table.Col>Date</Table.Col>} */}
              <Table.Col right>{isTablet ? 'Added' : 'Boosts added'}</Table.Col>
              {!isTablet && <Table.Col right>Current Boost</Table.Col>}
              <Table.Col center>Action</Table.Col>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {boostedProjects?.projects?.map((item, index) => {
              return (
                <Table.Row key={index}>
                  <Table.Col>
                    <TokenInfo
                      icon={item?.image ? item?.image : img}
                      name={item?.symbol}
                      label={item?.title}
                    />
                  </Table.Col>
                  {/* {!isTablet && <Table.Col>02 Jun 2024 - 7 PM</Table.Col>} */}
                  <Table.Col right>
                    <div className={`${style.score}`}>
                      {item?.personal_boosts}
                      <img src={fireIcon} alt='' />
                    </div>
                  </Table.Col>
                  {!isTablet && (
                    <Table.Col right>
                      <div className={`${style.score}`}>
                        {item?.boosts_count}
                        <img src={fireIcon} alt='' />
                      </div>
                    </Table.Col>
                  )}
                  <Table.Col center>
                    <Button sm filledGrey onClick={() => navigate(item?.url)}>
                      Detail
                    </Button>
                  </Table.Col>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table.Container>
      )}
    </div>
  );
};

export default BoostedSection;
