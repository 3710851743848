import { useMediaQuery } from 'usehooks-ts';
import Table from '../../../../components/Table/Table';
import style from './History.module.scss';
import { useState } from 'react';
import Button from '../../../../components/Button/Button';
import Empty from '../../../../components/Empty/Empty';
import firedIcon from '../../../../assets/icons/fired.svg';
import { useGetBoostersHistoryQuery } from '../../../../store/boosterApi';

const HistorySection = () => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const [isEmpty, setIsEmpty] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const { data: boostedHistories } = useGetBoostersHistoryQuery();

  console.log(boostedHistories, 'boostedHistories');

  return (
    <div className={style.history}>
      {isEmpty ? (
        <div className={style.empty}>
          <Empty
            icon={firedIcon}
            title={
              <>
                So far, you have <br /> not purchased Boost
              </>
            }
            text={
              <>
                The more boosts, the higher <br /> you are among other projects
              </>
            }
            button={<Button>Buy Boost</Button>}
          />
        </div>
      ) : (
        <Table.Container
          side={
            isMobile && (
              <Table.Side>
                <Table.Row>
                  <Table.Col>#</Table.Col>
                </Table.Row>
                <Table.Row>
                  <Table.Col>#0582FFF9</Table.Col>
                </Table.Row>
                <Table.Row>
                  <Table.Col>#0582FFF9</Table.Col>
                </Table.Row>
              </Table.Side>
            )
          }
        >
          <Table.Head>
            <Table.Row>
              {!isMobile && <Table.Col>#</Table.Col>}
              <Table.Col center>Boosts</Table.Col>
              <Table.Col center>Bonus</Table.Col>
              <Table.Col center>Price</Table.Col>
              <Table.Col>Date Created</Table.Col>
              <Table.Col>Status</Table.Col>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {boostedHistories?.history?.map((item, index) => {
              return (
                <Table.Row key={index}>
                  {!isMobile && <Table.Col>#0582FFF9</Table.Col>}
                  <Table.Col center>{item?.count}</Table.Col>
                  <Table.Col center>
                    <div className={`${style.card} ${style.yellow}`}>
                      {item?.bonus}X
                    </div>
                  </Table.Col>
                  <Table.Col center>${item?.price}</Table.Col>
                  <Table.Col>{formatDate(item?.created_at)}</Table.Col>
                  <Table.Col>
                    <div
                      className={`${style.card} ${style[item?.status?.color]} ${
                        style.big
                      }`}
                    >
                      {item?.status?.text}
                    </div>
                  </Table.Col>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table.Container>
      )}
    </div>
  );
};

export default HistorySection;
