import Text from '../../../../components/Text/Text';
import Title from '../../../../components/Title/Title';
import Button from '../../../../components/Button/Button';
import style from './BottomSection.module.scss';

import coinsImg from './assets/coins.png';
import contactUsImg from '../../../../assets/btn_icons/contact_us.svg';
import blogImg from '../../../../assets/btn_icons/blog.svg';
import dictionaryImg from '../../../../assets/btn_icons/dictionary.svg';
import advertisingImg from '../../../../assets/btn_icons/advertising.svg';
import partnersImg from '../../../../assets/btn_icons/partners.svg';
import { useNavigate } from 'react-router-dom';

const BottomSection = ({ menu }) => {
  console.log('menu', menu);
  const navigate = useNavigate();

  return (
    <div className={style.bottom}>
      <div className={style.bottomCol}>
        <div className={style.bottomRow}>
          <div className={`${style.bottomCard} ${style.yellow}`}>
            <div className={style.bottomCard__icon}>
              <img src={coinsImg} alt='' />
            </div>
            <Title.H2 className={style.bottomCard__title}>
              Your Favorite <br /> Coin Missing?
            </Title.H2>
            <Text grey center className={style.bottomCard__text}>
              List your favorite coin now! Get your community to vote for your
              coin and gain exposure.
            </Text>
            <Button filledGrey yellow className={style.bottomCard__btn}>
              Submit Coin
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M4.6665 11.3346L11.3332 4.66797M11.3332 4.66797H4.6665M11.3332 4.66797V11.3346'
                  stroke='#010106'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </Button>
          </div>
          <div className={`${style.bottomCard} ${style.blue}`}>
            <Title.H2 blue className={style.bottomCard__title}>
              View New Listings
            </Title.H2>
            <Text grey center className={style.bottomCard__text}>
              Click the button below to view the New Listings! These coins were
              just submitted.
            </Text>
            <Button filledGrey blue className={style.bottomCard__btn}>
              View New Listings
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M4.6665 11.3346L11.3332 4.66797M11.3332 4.66797H4.6665M11.3332 4.66797V11.3346'
                  stroke='#010106'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </Button>
          </div>
        </div>
        <div className={style.bottomText}>
          <div className={style.bottomText__item}>
            <Title.H2 className={style.bottomText__itemTitle}>
              How does Project work?
            </Title.H2>
            <Text grey className={style.bottomText__itemContent}>
              New cryptocurrency projects can be listed Applying Here. Once
              applied, they instantly become visible on the New Listings Page.
              After that, anyone can see and vote for the project. Get your
              community to vote on your project, because votes matter! Our
              ranking is simple: the highest votes is #1 on our website. The
              project will get exposure with all our visitors! Note on voting:
              Unique votes only count once for the "All Time" page, but can
              count every 24 hours on the "Today" page.
            </Text>
          </div>
          <div className={style.bottomText__item}>
            <Title.H2 className={style.bottomText__itemTitle}>
              Find the best new cryptocurrency projects
            </Title.H2>
            <Text grey className={style.bottomText__itemContent}>
              Did ever you wonder where people find the best new cryptocurrency
              projects, coins and tokens like Doge and Shiba Inu? They use
              websites like coinsniper.net. Cryptocurrency projects are listed
              here before CoinMarketCap, CoinGecko and major exchanges. Find the
              best crypto moonshots on our website. However: before investing
              always do your own research (DYOR)! Listing on coinsniper.net does
              NOT mean we endorse the project, they could be scams. Be careful
              with your investments.
            </Text>
          </div>
        </div>
      </div>
      <div className={style.bottomCol}>
        <div className={style.bottomLinks}>
          {menu?.map((item, index) => {
            return (
              <div
                className={style.bottomLinks__item}
                onClick={() => navigate(item?.url)}
                key={index}
              >
                <div className={style.bottomLinks__itemIcon}>
                  <img src={blogImg} alt='' />
                </div>
                <Title.H2 className={style.bottomLinks__itemTitle}>
                  {item?.title}
                </Title.H2>
                <Button onlyIcon className={style.bottomLinks__itemArrow}>
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 12L10 8L6 4'
                      stroke='white'
                      stroke-opacity='0.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </Button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BottomSection;
